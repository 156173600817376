/* eslint-disable no-nested-ternary */
import { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';
import { UnionDataInterface } from '@common/types/data';
import { IHeading } from '@store/features/headings/types';
import { ETableName } from '@common/utils/enums';
import { ColumnBaseProps, DataColumnProps } from '../../Column/types';

export const useRowTextContent = (
  headings: IHeading[],
  data: UnionDataInterface | undefined,
  tableName: ETableName | undefined
) => {
  const [textContent, setTextContent] = useState<
    Required<(ColumnBaseProps & { value: DataColumnProps['value'] })[]>
  >([]);
  const handleTextContext = useCallback(() => {
    const newData: Required<(ColumnBaseProps & { value: DataColumnProps['value'] })[]> =
      headings.map(prevData => ({
        ...prevData,
        value:
          data && tableName === ETableName.TOLLDOCUMENTS
            ? data[_.snakeCase(prevData.field as string) as keyof UnionDataInterface]
            : data && tableName === ETableName.TRANSACTIONS && prevData.heading === 'Vehicle Name'
            ? data['vehicle_name' as keyof UnionDataInterface]
            : data
            ? data[
                _.camelCase(
                  (prevData.field as string) || prevData.heading
                ) as keyof UnionDataInterface
              ]
            : ''
      }));
    setTextContent(newData);
  }, [data, headings, tableName]);

  useEffect(() => {
    if (data?.id) handleTextContext();
  }, [data, handleTextContext]);

  return { textContent };
};
