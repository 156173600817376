/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { formatNumber } from '@common/services/format';
import { ICalculatedToll } from '@api/types'; // Assuming ICalculatedToll and IBilledToll are the correct interface names
import { uniqueId } from 'lodash';
import { IBilledToll } from '@api/types/trip-response';
import { GPSTollTimezone } from '@common/services/format/formatTimeZone';
import styles from './table.module.scss';
import TableSkeleton from './TableSkeleton';

const Table = ({
  title,
  isLoading,
  data
}: {
  title: string;
  isLoading: boolean;
  data?: (ICalculatedToll | IBilledToll)[];
}) => {
  return (
    <div className={styles.container}>
      <h2>{title}</h2>
      {isLoading ? (
        <div>
          <TableSkeleton />
        </div>
      ) : (
        <>
          {data && data.length > 0 ? (
            <div>
              <table>
                <thead>
                  <tr>
                    <th>DATE/TIME</th>
                    <th>STATE</th>
                    <th>AGENCY</th>
                    <th>PLAZA</th>
                    <th>AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item: ICalculatedToll | IBilledToll) => (
                    <tr key={uniqueId()}>
                      <td>{GPSTollTimezone(item)}</td>
                      <td>{item?.state}</td>
                      <td>{'agency_name' in item ? item?.agency_name : item?.agencyName}</td>
                      <td>{'src_exit_plaza' in item ? item?.src_exit_plaza : item?.plaza}</td>
                      <td
                        className={
                          'missedTollUpdatedAt' in item && item?.missedTollUpdatedAt != null
                            ? styles['greyed-out']
                            : ''
                        }
                      >
                        {formatNumber(
                          'calculatedAmount' in item ? item?.calculatedAmount : item?.amount,
                          2,
                          '$'
                        ) || '0'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className={styles['no-data']}>
              <h3>No data to display</h3>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Table;
