import { TransactionDataInterface } from '@common/types/data';
import { IHeading } from '@store/features/headings/types';

export const headers: IHeading<TransactionDataInterface>[] = [
  {
    heading: 'status',
    field: 'statusMessage',
    active: false,
    filterType: 'text',
    placeholder: 'search status'
  },
  {
    heading: 'toll agency',
    field: 'tollAgency',
    active: true,
    filterType: 'text',
    placeholder: 'search toll agency'
  },
  {
    heading: 'plaza',
    field: 'plaza',
    active: true,
    filterType: 'text',
    placeholder: 'search plaza'
  },
  {
    heading: 'facility',
    field: 'facility',
    active: true,
    filterType: 'text',
    placeholder: 'search facility'
  },
  {
    heading: 'license plate',
    field: 'licensePlate',
    active: true,
    filterType: 'text',
    placeholder: 'search license plate'
  },
  // {
  //   heading: 'license plate state',
  //   field: 'licenseState',
  //   active: true,
  //   filterType: 'text',
  //   placeholder: 'search plate state'
  // },
  // {
  //   heading: 'license plate number',
  //   field: 'licenseNumber',
  //   active: true,
  //   filterType: 'text',
  //   placeholder: 'search plate number'
  // },
  {
    heading: 'tag/transponder',
    field: 'tagNumber',
    active: true,
    filterType: 'text',
    placeholder: 'search tag/transponder'
  },
  {
    heading: 'toll',
    field: 'calculatedAmount',
    active: true,
    filterType: 'number',
    placeholder: 'search toll amount'
  },
  {
    heading: 'fee',
    field: 'feeType',
    active: true,
    filterType: 'text',
    placeholder: 'search fee type'
  },
  {
    field: 'feeSubType',
    active: true,
    heading: 'fee sub type',
    filterType: 'text',
    placeholder: 'search fee subtype'
  },
  {
    heading: 'transaction date',
    field: 'date',
    active: true,
    filterType: 'date',
    placeholder: 'search transaction date'
  },
  {
    heading: 'upload date',
    field: 'uploadDate',
    active: false,
    filterType: 'date',
    placeholder: 'search upload date'
  },
  {
    heading: 'Transaction Id',
    field: 'tollBillId',
    active: false,
    filterType: 'text',
    placeholder: 'search transaction id'
  },
  {
    heading: 'Erroneous Toll Type',
    active: true,
    field: 'errorTollTypeDesc',
    filterType: 'text',
    placeholder: 'search error toll  type'
  },
  {
    heading: 'Vehicle Name',
    active: true,
    field: 'vehicle_name',
    filterType: 'text',
    placeholder: 'search vehicle name'
  }
];
