/*eslint-disable */
import { FieldArray, Form, useFormikContext } from 'formik';
import { useEffect, useRef, useState } from 'react';
import {
  VIN,
  Truck,
  Weight,
  Height,
  VehicleAxles,
  TollID,
  PlateNumber,
  Calendar,
  VehicleClass,
  PlateState,
  Plus,
  Delete
} from '@assets/icons';
import { SelectInput, TextInput } from '@common/ui/Form';
import dayjs from 'dayjs';
import { useGetListsData } from './hooks/useGetListsData';
import styles from './update-vehicle.module.scss';
import Accordion from '@common/ui/Accordion/Accordion';
import { v4 as uuid } from 'uuid';
import Button from '@common/ui/Button/Button';
import { WEB_BE_URL } from '@api/config';
import axios from 'axios';
import { useAppSelector } from '@store/hooks';
import { toast } from 'react-toastify';
import { TagInterface } from '@common/types/data/fleet.interface';
import { EAuthRole } from '@common/utils/enums';
import { CheckboxStateType } from '@common/ui/Checkbox/types';
import Modal from '@common/ui/Modal/Modal';
import { AnimatePresence } from 'framer-motion';
import { useEditPermission } from '@pages/tables/hooks/useEditPermission';
import { routes } from '@common/utils/route-names';
import { UIDateTimeformatHelper } from '@common/services/format/formatTimeZone';
import { EDeviceType } from '@common/utils/enums/tablenames';
import api from '@api/services/common';
import { MotionDiv } from '../../../modals/RowModal/shared';

const UpdateVehicle = ({ updatedAt }: { updatedAt: string }) => {
  const [firstTagTypes, setFirstTagTypes] = useState<string[]>([]);
  const [secondTagTypes, setSecondTagTypes] = useState<string[]>([]);
  const formRef = useRef<HTMLFormElement>(null);
  const {
    agencies,
    secAgencies,
    tagTypes,
    vehicleClasses,
    plateStates,
    devicePlans,
    billingStatuses
  } = useGetListsData();
  const hasEditAccess = !useEditPermission(routes.fleet);
  const [isFormResetted, setIsFormResetted] = useState(false);
  const formik = useFormikContext<AnyObject>();
  const orderNow = useAppSelector(state => state.customer.geotabOrderNow);
  const userRole = useAppSelector(state => state.auth.app_role);
  const isMetric = useAppSelector(state => state.auth.isMetric);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [isShowConfirmationChecked, setIsShowConfirmationChecked] =
    useState<CheckboxStateType>('unchecked');

  const isExecutingRef = useRef(false);
  const isInitialLoadDone = useRef(false);
  const [disabled, setDisabled] = useState({
    primary: {
      issuingAgency: false,
      tagType: false
    },
    secondary: {
      issuingAgency: false,
      tagType: false
    }
  });
  const initialTagValues = useRef({
    tagTransponder: formik.values.tagTransponder,
    tagType: formik.values.tagType,
    issuingAgencyIdD: formik.values.issuingAgencyIdD,
    tollConsolidator: formik.values.tollConsolidator,
    secIssuingAgencyIdD: formik.values.secIssuingAgencyIdD
  });
  const tagIssuingAgencies = useAppSelector(state => state.table.tagIssuingAgencyList);
  const multiTag = (isDisabled: boolean = false, key: string) => (
    <div className={styles['accordion-header']} key={key}>
      <TextInput
        className={styles.input}
        label="Tag/Transponder"
        onKeyUp={e => handleKeyUp(e, 'primary')}
        name="tagTransponder"
        icon={<VehicleAxles />}
        placeholder="Enter Tag/Transponder"
        disabled={isDisabled}
        onSetValue={(e: any) => {
          formik.values['tagTransponder'] = e.target.value;
        }}
      />
      <SelectInput
        className={styles.input}
        label="Issuing Agency"
        formField="issuingAgencyIdD"
        icon={<TollID />}
        includeSearch
        values={agencies}
        dropdownContainerEl={formRef}
        placeholder="Select Issuing Agency"
        disabled={isDisabled}
      />
      <SelectInput
        className={styles.input}
        label="Tag Type"
        formField="tagType"
        icon={<TollID />}
        includeSearch
        values={tagTypes}
        dropdownContainerEl={formRef}
        placeholder="Select Tag Type"
        disabled
      />
    </div>
  );
  const showSecondaryTagAction = () => {
    formik.setFieldValue('secondTagExists', !formik.values?.secondTagExists);
    formik.setFieldValue('secTagType', null);
  };
  const deleteSecondaryTag = (type: string) => {
    if (type) {
      if (type === 'primary') {
        isExecutingRef.current = true;
        formik.setFieldValue('tagTransponder', formik.values.secTagTransponder);
        formik.setFieldValue('issuingAgencyId', formik.values.secIssuingAgencyId);
        formik.setFieldValue('issuingAgencyIdD', formik.values.secIssuingAgencyIdD);
        formik.setFieldValue('tagType', formik.values.secTagType);
      }
      formik.setFieldValue('secondTagExists', !formik.values?.secondTagExists);
      formik.setFieldValue('secTagTransponder', '');
      formik.setFieldValue('secIssuingAgencyId', null);
      formik.setFieldValue('secIssuingAgencyIdD', null);
      formik.setFieldValue('secTagType', null);
    }
  };
  const parseTagTransponder = async (
    e: any,
    type: string,
    consolidator: { type: string; index: number } | null = null
  ) => {
    if (e.target.value && e.target.value.length > 0) {
      try {
        const parseRes = (
          await axios.post(`${WEB_BE_URL}/customer-onboard/parse-tagnumber`, {
            tag_number: e.target.value
          })
        ).data;
        if (parseRes.isSuccess && parseRes.data) {
          const parsedIssuingAgency = tagIssuingAgencies?.find(x => Number(x.id) === parseRes.data);
          if (parsedIssuingAgency && parsedIssuingAgency.name) {
            if (type === 'primary') {
              formik.setFieldValue('issuingAgencyIdD', parsedIssuingAgency?.name);
              if (parsedIssuingAgency?.is_toll_consolidator) {
                disabled.primary.tagType = true;
                formik.setFieldValue('tagType', 'Third Party');
                formik.setFieldValue('tollConsolidator', true);
              } else {
                disabled.primary.tagType = false;
                formik.setFieldValue('tollConsolidator', false);
              }
            } else if (type === 'secondary') {
              formik.setFieldValue('secIssuingAgencyIdD', parsedIssuingAgency?.name);
              if (parsedIssuingAgency?.is_toll_consolidator) {
                disabled.secondary.tagType = true;
                formik.setFieldValue('secTagType', 'Third Party');
              } else {
                disabled.secondary.tagType = false;
              }
            } else if (type === 'consolidator') {
              if (consolidator?.type === 'child') {
                const childTags = [...formik.values.childTags];
                childTags[consolidator.index].issuingAgencyIdD = parsedIssuingAgency?.name;
                formik.setFieldValue('childTags', childTags);
              }
            }
          } else {
            if (type === 'primary') {
              formik.setFieldValue('issuingAgencyIdD', 'None');
            } else if (type === 'secondary') {
              formik.setFieldValue('secIssuingAgencyIdD', 'None');
            }
          }
        } else {
          if (!consolidator) {
            formik.setFieldValue('tollConsolidator', false);
            if (type === 'primary') {
              formik.setFieldValue('tagTransponder', e.target.value);
              formik.setFieldValue('issuingAgencyIdD', '');
              formik.setFieldValue('tagType', '');
            } else if (type === 'secondary') {
              formik.setFieldValue('secTagTransponder', e.target.value);
              formik.setFieldValue('secIssuingAgencyIdD', '');
              formik.setFieldValue('secTagType', '');
            }
          } else if (consolidator && type === 'consolidator') {
            if (consolidator.type === 'child') {
              const childTags = [...formik.values.childTags];
              childTags[consolidator.index].tagTransponder = e.target.value;
              childTags[consolidator.index].issuingAgencyIdD = '';
              childTags[consolidator.index].tagType = '';
              formik.setFieldValue('childTags', childTags);
            }
          }
        }
        setDisabled({ ...disabled });
      } catch (error) {
        console.log('Error in parsing tag transponder ==> ', error);
      }
    } else {
      if (!consolidator) {
        formik.setFieldValue('tollConsolidator', false);
        if (type === 'primary') {
          formik.setFieldValue('tagTransponder', e.target.value);
          formik.setFieldValue('issuingAgencyIdD', '');
          formik.setFieldValue('tagType', '');
        } else if (type === 'secondary') {
          formik.setFieldValue('secTagTransponder', e.target.value);
          formik.setFieldValue('secIssuingAgencyIdD', '');
          formik.setFieldValue('secTagType', '');
        }
      } else if (consolidator && type === 'consolidator') {
        if (consolidator.type === 'child') {
          const childTags = [...formik.values.childTags];
          childTags[consolidator.index].tagTransponder = e.target.value;
          childTags[consolidator.index].issuingAgencyIdD = '';
          childTags[consolidator.index].tagType = '';
          formik.setFieldValue('childTags', childTags);
        }
      }
    }
  };

  const typingTimerRef = useRef<NodeJS.Timeout | null>(null);
  const pauseThreshold = 500; // 0.5 second

  const handleKeyUp = (
    e: any,
    type: string,
    consolidator: { type: string; index: number } | null = null
  ) => {
    if (typingTimerRef.current) {
      clearTimeout(typingTimerRef.current);
    }

    typingTimerRef.current = setTimeout(
      () => checkTypingPause(e, type, consolidator),
      pauseThreshold
    );
  };

  const checkTypingPause = (
    e: any,
    type: string,
    consolidator: { type: string; index: number } | null = null
  ) => {
    parseTagTransponder(e, type, consolidator);
  };

  const deleteChildTag = (index: number) => {
    if (index !== null) {
      let childTags: TagInterface[] = formik.values.childTags;
      if (childTags) {
        const updatedChildTags = [...childTags]; // Create a new array
        updatedChildTags.splice(index, 1); // Modify the new array
        formik.setFieldValue('childTags', updatedChildTags); // Assign the new array back to formik.values.childTags
      }
    }
  };
  const addChildTag = () => {
    let childTags: TagInterface[] = formik.values.childTags || [];
    if (childTags) {
      const updatedChildTags = [...childTags]; // Create a new array
      updatedChildTags.push({
        tagTypeId: '',
        tagType: '',
        tagTransponder: '',
        issuingAgencyId: null,
        issuingAgencyIdD: '',
        agencyName: ''
      }); // Modify the new array
      formik.setFieldValue('childTags', updatedChildTags); // Assign the new array back to formik.values.childTags
    }
  };

  const issuingAgencyAction = async (value: string, type: string) => {
    const matchedIssuingAgency = tagIssuingAgencies?.find(x => x.name === value);
    if (matchedIssuingAgency?.is_toll_consolidator) {
      if (type === 'primary') {
        formik.setFieldValue('tagType', 'Third Party');
        disabled.primary.tagType = true;
      } else if (type === 'secondary') {
        formik.setFieldValue('secTagType', 'Third Party');
        disabled.secondary.tagType = true;
      }
      formik.setFieldValue('tollConsolidator', true);
    } else {
      if (type === 'primary') {
        disabled.primary.tagType = false;
      } else if (type === 'secondary') {
        disabled.secondary.tagType = false;
      }
      formik.setFieldValue('tollConsolidator', false);
    }
    setDisabled({ ...disabled });
  };

  const deleteChildTags = () => {
    formik.setFieldValue('childTags', null);
    setShowConfirmationDialog(false);
    // formik.setFieldValue('childTags', null);
    const currentIssuingAgency = tagIssuingAgencies?.find(
      x => x.name === formik.values.issuingAgencyIdD
    );
    if (currentIssuingAgency && !currentIssuingAgency.is_toll_consolidator) {
      formik.setFieldValue('tollConsolidator', false);
    }
    toast.warn(`Note : Existing child tags will be deleted`, {
      position: toast.POSITION.TOP_CENTER
    });
  };

  const resetParentTagValues = () => {
    formik.setFieldValue('tagTransponder', initialTagValues.current.tagTransponder);
    formik.setFieldValue('tagType', initialTagValues.current.tagType);
    formik.setFieldValue('issuingAgencyIdD', initialTagValues.current.issuingAgencyIdD);
    formik.setFieldValue('tollConsolidator', initialTagValues.current.tollConsolidator);
    setShowConfirmationDialog(false);
    setIsShowConfirmationChecked('unchecked');
  };

  async function setTagTypes(value: string, type: string, isLoadedInitially?: boolean) {
    try {
      const matchedIssuingAgency = tagIssuingAgencies?.find(x => x.name === value);

      if (!matchedIssuingAgency?.is_toll_consolidator) {
        const tagTypeField = type === 'Primary' ? 'tagType' : 'secTagType';

        if (!matchedIssuingAgency?.id) {
          formik.setFieldValue(tagTypeField, 'None');
          type === 'Primary' ? setFirstTagTypes([]) : setSecondTagTypes([]);
          disabled[type === 'Primary' ? 'primary' : 'secondary'].tagType = false;
          return;
        }

        const parseTagType = await api.get(
          `/customer-onboard/parse-agency?agency_id=${matchedIssuingAgency.id}`
        );

        if (parseTagType?.data?.length > 1) {
          // formik.setFieldValue(tagTypeField, '');
          if (type === 'Primary') {
            setFirstTagTypes(parseTagType.data);
          } else {
            setSecondTagTypes(parseTagType.data);
          }
          disabled[type === 'Primary' ? 'primary' : 'secondary'].tagType = false;
        } else if (parseTagType?.data?.length === 1) {
          formik.setFieldValue(tagTypeField, parseTagType.data[0]);
          disabled[type === 'Primary' ? 'primary' : 'secondary'].tagType = true;
        } else {
          if (!isLoadedInitially) {
            formik.setFieldValue(tagTypeField, 'None');
            type === 'Primary' ? setFirstTagTypes([]) : setSecondTagTypes([]);
            disabled[type === 'Primary' ? 'primary' : 'secondary'].tagType = false;
          }
        }
      }
    } catch (err) {
      toast.error(`Can't able find Tag Type${err}`, {
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  useEffect(() => {
    if (!isFormResetted) {
      setIsFormResetted(true);
      formik.resetForm();
    }
    if (formik.values.issuingAgencyIdD) {
      issuingAgencyAction(formik.values.issuingAgencyIdD, 'primary');
    }

    if (
      (initialTagValues.current.issuingAgencyIdD !== formik.values.issuingAgencyIdD ||
        initialTagValues.current.tagType !== formik.values.tagType) &&
      isInitialLoadDone
    ) {
      setTagTypes(formik.values.issuingAgencyIdD, 'Primary');
      // isExecutingRef.current = true;
    }

    // setTagTypes(formik.values.issuingAgencyIdD, 'Primary');

    if (!formik.values.isTouched) {
      formik.setFieldValue('isTouched', false);
    } else {
      formik.setFieldValue('isTouched', !formik.values.isTouched);
    }
    isExecutingRef.current = false;
  }, [formik.values.issuingAgencyIdD]);

  useEffect(() => {
    isInitialLoadDone.current = true;
    if (formik.values.issuingAgencyIdD) {
      setTagTypes(formik.values.issuingAgencyIdD, 'Primary', true);
    }
    if (formik.values.secIssuingAgencyIdD) {
      setTagTypes(formik.values.secIssuingAgencyIdD, 'Secondary', true);
    }
  }, []);

  useEffect(() => {
    if (!isFormResetted) {
      setIsFormResetted(true);
      formik.resetForm();
    }
    if (formik.values.secIssuingAgencyIdD) {
      issuingAgencyAction(formik.values.secIssuingAgencyIdD, 'secondary');
    }
    if (
      initialTagValues.current.secIssuingAgencyIdD !== formik.values.secIssuingAgencyIdD &&
      isInitialLoadDone
    ) {
      setTagTypes(formik.values.secIssuingAgencyIdD, 'secondary');
    }
    // setTagTypes(formik.values.secIssuingAgencyIdD, 'secondary');
  }, [formik.values.secIssuingAgencyIdD]);

  useEffect(() => {
    if (!isFormResetted) {
      setIsFormResetted(true);
      formik.resetForm();
    }
    /* Clear child tags if parent tag is updated */

    /* Compare the previous values with the new values */
    if (
      formik.values.childTags &&
      formik.values.childTags.length > 0 &&
      (initialTagValues.current.tagTransponder !== formik.values.tagTransponder ||
        initialTagValues.current.tagType !== formik.values.tagType ||
        initialTagValues.current.issuingAgencyIdD !== formik.values.issuingAgencyIdD)
    ) {
      if (isShowConfirmationChecked === 'unchecked') {
        setShowConfirmationDialog(true);
      }
    }
    /* Update the previous values with the new values */
    if (isShowConfirmationChecked === 'checked') {
      initialTagValues.current = {
        tagTransponder: formik.values.tagTransponder,
        tagType: formik.values.tagType,
        issuingAgencyIdD: formik.values.issuingAgencyIdD,
        tollConsolidator: formik.values.tollConsolidator,
        secIssuingAgencyIdD: formik.values.secIssuingAgencyIdD
      };
    }
  }, [formik.values.tagTransponder, formik.values.tagType, formik.values.issuingAgencyIdD]);

  return (
    <>
      <Form className={styles.container} ref={formRef}>
        {updatedAt && dayjs(updatedAt).isValid() && (
          <p className={styles['last-updated']}>
            Last updated on {UIDateTimeformatHelper(updatedAt)}
          </p>
        )}
        <section className={styles.form}>
          <TextInput
            className={styles.input}
            label="Vehicle Name"
            name="name"
            icon={<TollID />}
            placeholder="Enter Vehicle Name"
            disabled={hasEditAccess || formik.values.deviceType !== EDeviceType.MANUAL}
          />
          <SelectInput
            className={styles.input}
            label="Vehicle Class"
            formField="vehicleClassD"
            icon={<VehicleClass className={styles.class} />}
            values={vehicleClasses}
            dropdownContainerEl={formRef}
            placeholder="Select Vehicle Class"
            disabled={hasEditAccess}
          />
          <SelectInput
            className={styles.input}
            label="License Plate State"
            formField="plateStateD"
            icon={<PlateState />}
            values={plateStates}
            dropdownContainerEl={formRef}
            placeholder="Select Plate State"
            disabled={hasEditAccess}
          />
          {/* <TextInput
          className={styles.input}
          label="License Plate"
          name="licensePlate"
          icon={<PlateState />}
          // disabled
        /> */}

          <TextInput
            className={styles.input}
            label="License Plate Number"
            name="plateNumber"
            icon={<PlateNumber />}
            placeholder="Enter Plate Number"
            disabled={hasEditAccess}
          />
          <TextInput
            className={styles.input}
            label="VIN"
            name="vin"
            icon={<VIN />}
            placeholder="Enter Vehicle VIN"
            disabled
          />
          <SelectInput
            className={styles.input}
            label="Vehicle Type"
            formField="type"
            icon={<Truck />}
            values={['AUTO', 'TRUCK', 'TRAILER']}
            dropdownContainerEl={formRef}
            placeholder="Select Vehicle Type"
            disabled={hasEditAccess}
          />
          <TextInput
            className={styles.input}
            label="Device Type"
            name="deviceTypeName"
            icon={<TollID />}
            disabled
          />
          {/* <TextInput
          className={styles.input}
          label="Vehicle Model"
          name="vehicleModel"
          icon={<Truck />}
          // disabled
        /> */}
          <TextInput
            className={styles.input}
            label={`Weight, ${isMetric ? 'kg' : 'lbs'}`}
            name="weight"
            type="number"
            icon={<Weight />}
            placeholder="Enter Vehicle Weight"
            disabled={hasEditAccess}
          />
          <TextInput
            className={styles.input}
            label={`Height, ${isMetric ? 'meter' : 'inch'}`}
            name="height"
            type="number"
            icon={<Height />}
            placeholder="Enter Vehicle Height"
            disabled={hasEditAccess}
          />
          <SelectInput
            className={styles.input}
            label="Vehicle Axles"
            formField="axles"
            icon={<VehicleAxles />}
            values={[2, 3, 4, 5]}
            dropdownContainerEl={formRef}
            placeholder="Select Vehicle Axles"
            disabled={hasEditAccess}
          />
          {/* <TextInput
          className={styles.input}
          label="Vehicle Wheels"
          name="wheels"
          icon={<VehicleTires />}
          // disabled
        /> */}
          {/* // <TextInput
          //   className={styles.input}
          //   label="Current Driver"
          //   name="currentDriver"
          //   icon={<Profile />}
          //   placeholder="Enter Current Driver"
          //   // disabled
          // /> */}
          <TextInput
            className={styles.input}
            label="Date of Creation"
            name="createdAt"
            icon={<Calendar />}
            disabled
          />
          <SelectInput
            className={styles.input}
            label="Device Plan"
            formField="devicePlanD"
            icon={<Truck />}
            values={devicePlans}
            dropdownContainerEl={formRef}
            disabled
          />
          <SelectInput
            className={styles.input}
            label="Billing Status"
            formField="billingStatusD"
            icon={<TollID />}
            includeSearch
            values={billingStatuses.filter(
              bs => bs.toLowerCase() !== 'deleted' && bs.toLowerCase() !== 'unsubscribed tolling'
            )}
            dropdownContainerEl={formRef}
            disabled={
              orderNow ||
              (userRole &&
              userRole === EAuthRole.SUPER_ADMIN &&
              formik.values.billingStatusD !== 'Deleted'
                ? false
                : true)
            }
          />
          <TextInput
            className={styles.input}
            label="Billing Status Updated On"
            name="billingStatusUpdatedAt"
            icon={<Calendar />}
            disabled
          />
          <div className={styles['transponder-sel']}>
            {/* <Checkbox
            className={styles['tag-consolidator']}
            state={formik.values?.tollConsolidator ? 'checked' : 'unchecked'}
            label="Tag Consolidator"
            isLabelBold
            onClick={() => {
              formik.setFieldValue('tollConsolidator', !formik.values?.tollConsolidator);
            }}
            // onClick={() => formik.values?.tollConsolidator}
          /> */}
            {formik.values?.tollConsolidator || formik.values.childTags?.length > 0 ? (
              <Accordion
                headerElement={multiTag(hasEditAccess, uuid())}
                className={styles.accordion}
                onlyArrowClick
                // disabled={formik.values.childTags?.length === 0}
              >
                <div className={styles['multi-tag-wrapper']}>
                  {formik.values.childTags?.length > 0 ? (
                    <FieldArray
                      name="childTags"
                      render={() => (
                        <>
                          {formik.values.childTags.map((tag: TagInterface, index: number) => (
                            <div className={styles['accordion-header']} key={index}>
                              <TextInput
                                className={styles.input}
                                label="Tag/Transponder"
                                onKeyUp={e =>
                                  handleKeyUp(e, 'consolidator', {
                                    index,
                                    type: 'child'
                                  })
                                }
                                name={`childTags[${index}]tagTransponder`}
                                icon={<VehicleAxles />}
                                placeholder="Enter Tag/Transponder"
                                disabled={
                                  (userRole && userRole === EAuthRole.SUPER_ADMIN ? false : true) ||
                                  hasEditAccess
                                }
                              />
                              <SelectInput
                                className={styles.input}
                                label="Issuing Agency"
                                formField={`childTags[${index}]issuingAgencyIdD`}
                                icon={<TollID />}
                                includeSearch
                                values={secAgencies}
                                dropdownContainerEl={formRef}
                                placeholder="Select Issuing Agency"
                                disabled={
                                  (userRole && userRole === EAuthRole.SUPER_ADMIN ? false : true) ||
                                  hasEditAccess
                                }
                              />
                              <SelectInput
                                className={styles.input}
                                label="Tag Type"
                                formField={`childTags[${index}]tagType`}
                                icon={<TollID />}
                                includeSearch
                                values={tagTypes}
                                dropdownContainerEl={formRef}
                                placeholder="Select Tag Type"
                                disabled={
                                  (userRole && userRole === EAuthRole.SUPER_ADMIN ? false : true) ||
                                  hasEditAccess
                                }
                              />
                              <Button
                                className={styles['del-sec-tag']}
                                appearance="icon"
                                variant="secondary"
                                onClick={() => deleteChildTag(index)}
                                icon={<Delete />}
                                disabled={
                                  (userRole && userRole === EAuthRole.SUPER_ADMIN ? false : true) ||
                                  hasEditAccess
                                }
                                size="xs"
                              />
                            </div>
                          ))}
                        </>
                      )}
                    />
                  ) : (
                    <div className={styles['empty-child-tag']}>
                      <p className={styles.text}>No child tags found.</p>
                    </div>
                  )}
                  {userRole &&
                    userRole === EAuthRole.SUPER_ADMIN &&
                    (formik.values.childTags?.length < 2 || !formik.values.childTags) && (
                      <Button
                        className={styles['add-child-tag']}
                        appearance="button"
                        variant="primary"
                        onClick={() => addChildTag()}
                        icon={<Plus />}
                        label="Toll Device"
                        size="xs"
                        disabled={hasEditAccess}
                      />
                    )}
                </div>
                {/* )} */}
              </Accordion>
            ) : (
              <>
                <div className={styles['multi-tag']}>
                  <div className={styles['accordion-header']}>
                    <TextInput
                      className={styles.input}
                      label="Tag/Transponder"
                      onKeyUp={e => handleKeyUp(e, 'primary')}
                      name="tagTransponder"
                      icon={<VehicleAxles />}
                      placeholder="Enter Tag/Transponder"
                      disabled={hasEditAccess}
                    />
                    <SelectInput
                      className={styles.input}
                      label="Issuing Agency"
                      formField="issuingAgencyIdD"
                      icon={<TollID />}
                      includeSearch
                      values={agencies}
                      dropdownContainerEl={formRef}
                      disabled={disabled.primary.issuingAgency || hasEditAccess}
                      placeholder="Select Issuing Agency"
                    />
                    <SelectInput
                      className={styles.input}
                      label="Tag Type"
                      formField="tagType"
                      icon={<TollID />}
                      includeSearch
                      values={firstTagTypes.length > 0 ? firstTagTypes : tagTypes}
                      dropdownContainerEl={formRef}
                      disabled={disabled.primary.tagType || hasEditAccess}
                      placeholder="Select Tag Type"
                    />
                    <Button
                      className={styles['del-sec-tag']}
                      appearance="icon"
                      variant="secondary"
                      onClick={() => deleteSecondaryTag('primary')}
                      icon={<Delete />}
                      disabled={!formik.values?.secondTagExists || hasEditAccess}
                      size="xs"
                    />
                  </div>
                  {formik.values?.secondTagExists && (
                    <div className={styles['second-tag']}>
                      <TextInput
                        className={styles.input}
                        label="Tag/Transponder"
                        name="secTagTransponder"
                        onKeyUp={e => handleKeyUp(e, 'secondary')}
                        icon={<VehicleAxles />}
                        placeholder="Enter Tag/Transponder"
                        disabled={hasEditAccess}
                      />
                      <SelectInput
                        className={styles.input}
                        label="Issuing Agency"
                        formField="secIssuingAgencyIdD"
                        icon={<TollID />}
                        includeSearch
                        values={secAgencies}
                        dropdownContainerEl={formRef}
                        disabled={disabled.secondary.issuingAgency || hasEditAccess}
                        placeholder="Select Issuing Agency"
                      />
                      <SelectInput
                        className={styles.input}
                        label="Tag Type"
                        formField="secTagType"
                        icon={<TollID />}
                        includeSearch
                        values={secondTagTypes.length > 0 ? secondTagTypes : tagTypes}
                        dropdownContainerEl={formRef}
                        disabled={disabled.secondary.tagType || hasEditAccess}
                        placeholder="Select Tag Type"
                      />
                      <Button
                        className={styles['del-sec-tag']}
                        appearance="icon"
                        variant="secondary"
                        onClick={() => deleteSecondaryTag('secondary')}
                        icon={<Delete />}
                        size="xs"
                        disabled={hasEditAccess}
                      />
                    </div>
                  )}
                </div>
                {!formik.values?.secondTagExists && (
                  <Button
                    className={styles['add-toll-device']}
                    appearance="button"
                    variant="primary"
                    onClick={showSecondaryTagAction}
                    icon={<Plus />}
                    label="Toll Device"
                    size="xs"
                    disabled={hasEditAccess}
                  />
                )}
              </>
            )}
          </div>
        </section>
      </Form>
      {showConfirmationDialog && (
        <Modal
          className={styles.modal}
          headerClassName={styles['modal-header']}
          footerClassName={styles['modal-footer']}
          withCheckbox
          checkboxLabel="Don't show again"
          isChecked={isShowConfirmationChecked}
          handleCheckbox={() =>
            setIsShowConfirmationChecked(
              isShowConfirmationChecked === 'checked' ? 'unchecked' : 'checked'
            )
          }
          title={`Warning`}
          customCloseFunc={resetParentTagValues}
          customPrimaryButton={
            <Button
              appearance="button"
              label={`Proceed`}
              variant="primary"
              size="xs"
              onClick={deleteChildTags}
              disabled={hasEditAccess}
            />
          }
        >
          <AnimatePresence exitBeforeEnter initial={false}>
            <MotionDiv activeTitle="activeTitle" prevTitle="prevTitle">
              <article className={styles.container}>
                <p>
                  You are about to change the values of the <b>Parent Tag</b> which will result in{' '}
                  <b>deletion </b>
                  of its child tags.
                </p>
              </article>
            </MotionDiv>
          </AnimatePresence>
        </Modal>
      )}
    </>
  );
};

export default UpdateVehicle;
